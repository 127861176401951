@import "abstracts/vars";
@import "abstracts/mixins";
.cookie-notification {
    @include font-size(1.4);
    opacity: 0.8;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 20px 0 0;
    color: $bg-basic-color;
    text-align: center;
    z-index: 1000;
    background: url(../Images/overlay-bg.png);
    .component-content {
        position: relative;
        height: auto;
        width: 90%;
        padding: 15px;
        margin: 0 auto;
    }
    a {
        color: $bg-basic-color;
        &:hover, &:focus {
            color: $bg-basic-color;
        }
    }
    .close {
        transform: scale(1.3, 0.95);
        @include font-size(3.5);
        position: absolute;
        top: -10px;
        right: 8px;
        font-weight: bold;
    }
    .learnMore {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
    }
    .submit {
        display: inline-block;
        vertical-align: middle;
        padding: 20px 0;
        a {
            @include font-size(1.3);
            @include border-basic();
            text-decoration: none;
            font-weight: bold;
            background: $bg-basic-color;
            padding: 5px 10px;
            cursor: pointer;
            color: $text-black;
        }
    }
}
